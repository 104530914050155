import { client } from './client';

// Error log
export const insertErrorApi = async (body) => {
  try{
    const url = `https://qaapi.badsami.com/be/error_log`
    const response = await client(url, body, 'POST');
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// Login
export const checkUserApi = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/checkUser`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// Reset password, forgot password
export const resetPasswordApi = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/resetPassword`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// profile
export const fetchDetailsApi = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/fetchDetails`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// fetch config
export const fetchConfigApi = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/fetch_config`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// post config
export const postConfig = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/postConfiguration`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// post details
export const postDetailsApi = async (body,jwt) => {
  try
  {
  console.log('body5',body)
  const url = `https://qaapi.badsami.com/be/postDetails`
  const response = await client(url, body, 'POST', jwt);
  console.log('res5',response)
  return response;
  }
  catch(error){
    console.log(error)
  }
}

// rfp generation
export const RfpResponse = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/rfp_gen`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// rfi generation
export const RfiResponse = async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/rfi_gen`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// compliance generation
export const fetchCompliance= async (body, jwt) => {
  try{
    const url = `https://qaapi.badsami.com/be/complianceGeneration`
    const response = await client(url, body, 'POST', jwt);
    return response;
  }
  catch(error){
    console.log(error)
  }
}

// Writting Scoring
export const writtingscoring= async (body, jwt) => {
  try{
  console.log("I am in");
  const url = `https://qaapi.badsami.com/be/writingsScoring`
  const response = await client(url, body, 'POST',jwt);
  console.log(response)
  return response;
}
catch(error){
  console.log(error)
}
}

// metadata Scoring 
export const metadataScoring= async (body, jwt) => {
  try{
  console.log("I am in");
  const url = `https://qaapi.badsami.com/be/metadataScoring`
  const response = await client(url, body, 'POST',jwt);
  console.log(response)
  return response;
}
catch(error){
  console.log(error)
}
}